html,body{
  overflow-x: hidden;
}
.fs-18i{
  font-size: 18px !important;
}
.w-100i{
  width: 100% !important;
}
.h-50i{
  height: 50px !important;
}
.p-0i{
  padding: 0 !important;
}
.pl-0{
  padding-left: 0;
}
.fs-18i{
  font-size: 18px !important;
}
.fs-24{
  font-size: 24px;
}
.block-modal{
  display: block;
  opacity: 1;
}
.hide-modal{
  display: none;
  opacity: 0;
}

.header-logo{
  width: 100px !important;
  height: 70px !important;
}